import React from "react";
import PropTypes from "prop-types";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import withTrans from "root/i18n/withTrans";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/AboutHero";
import AboutResult from "root/sections/AboutResult";
import AboutSmiles from "root/sections/AboutSmiles";
import Footer from "root/components/Footer";

/* eslint-disable id-length */
const AboutPage = ({ t }) => {
  return (
    <div>
      <SEO title={t("pages.about.label")} description="" keywords="" />
      <Layout>
        <Navbar color="blue" currentPage="about" translations={t("pages")} />
        <Hero translations={t("about.hero")} />
        <AboutResult translations={t("about.result")} />
        <AboutSmiles translations={t("about.smiles")} />
        <Footer translations={t("pages")} />
      </Layout>
    </div>
  );
};

AboutPage.propTypes = {
  t: PropTypes.func.isRequired,
};
/* eslint-enable id-length */

export default withTrans(AboutPage);
