import React from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Wellchair from "root/components/SvgColor/Wellchair";

import styles from "./index.module.css";

const mobileW = 800;

const AboutResult = ({
  translations: { subtitle, description, subtitle2, description2 },
}) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.stick}>
          <Wellchair color="yellow" />
        </div>
        <div className={styles.grid}>
          <Grid
            columns={dimensions.width > mobileW ? 54 : 18}
            lines={dimensions.width > mobileW ? 27 : 9}
            color="yellow"
            opacity={0.5}
          />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.goal}>
          <div className={styles.title}>
            <Typography color="blue" weight="medium" variant="h3">
              {subtitle}
            </Typography>
          </div>
          <div>
            <Typography color="black">{description}</Typography>
          </div>
        </div>
        <div className={styles.goal}>
          <div className={styles.title}>
            <Typography color="blue" weight="medium" variant="h3">
              {subtitle2}
            </Typography>
          </div>
          <div>
            <Typography color="black">{description2}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutResult.propTypes = {
  translations: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subtitle2: PropTypes.string.isRequired,
    description2: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutResult;
